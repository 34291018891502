.App {
    text-align: center;
    
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
    font-weight: 200;
    font-family: Interstate;
    font-display: fallback;
    src: url(../public/fonts/mkb-interstate-light.woff2) format("woff2"),
         url(../public/fonts/mkb-interstate-light.woff) format("woff")
}

@font-face {
    font-weight: 400;
    font-family: Interstate;
    font-display: fallback;
    src: url(../public/fonts/mkb-interstate.woff2) format("woff2"),url(../public/fonts/mkb-interstate.woff) format("woff")
}

@font-face {
    font-weight: 400;
    font-family: Interstate;
    font-display: fallback;
    font-style: italic;
    src: url(../public/fonts/mkb-interstate-italic.woff2) format("woff2"),url(../public/fonts/mkb-interstate-italic.woff) format("woff")
}

@font-face {
    font-weight: 700;
    font-family: Interstate;
    font-display: fallback;
    src: url(../public/fonts/mkb-interstate-bold.woff2) format("woff2"),url(../public/fonts/mkb-interstate-bold.woff) format("woff")
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
    font-family: Interstate,serif;
}

html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
}

ol {
    margin: 0px;
    margin-block-start: 0em;
    padding-inline-start: 0px;
    padding-left:0px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration:underline;
}

a:visited {
    color:#000;
}

li {
    margin:0px;
}

nav {
    margin:0px;
    padding:0px;
}

.header-container {
    display: flex;
    width: 100%;
    height: 128px;
    background-color: rgb(0 103 165);
}

.header-container-margin {
    display: flex;
    margin-left: 2.25rem;
    margin-right: 2.25rem;
    width:100%;
}
.header-container-inner {
    width:100%;
    max-width: 972px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.header-image {
    margin-top: auto;
    margin-bottom: auto;
    width: 10rem;
}

.header-customer {
    color:#fff;
}
.header-logout {
    color:#fff;
}

.body-container {
    display: flex;
    width: 100%;
}

.body-container-margin {
    display: flex;
    margin-left: 2.25rem;
    margin-right: 2.25rem;
    width:100%;
}

.body-container-inner {
    width: 100%;
    max-width: 972px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

nav.bread-crumb {
    padding-top:0.65rem;
}

svg.bread-crumb-icon {
    width: 1rem;
    height: 1rem;
    margin-top: 0.15rem;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

.flex {
    display:flex;
}

.flex-wrap {
    flex-wrap:wrap;
}

.full-width {
    width:100%;
}

.service-report-previewImage-container {
    position:relative;
    width: 10rem;
    height: 10rem;
    margin: 1rem;
}
.service-report-previewImage {
    width: inherit;
    height: inherit;
    float: left;
}

.service-report-previewImage-fileInput {
    display:none;
}

.service-report-create-objectName{
    font-weight:500;
    width:100%;
}

.service-report-create-component {
    min-height:665px
}

.service-report-list-component {
    min-height:900px;
}

.service-report-previewImage-remove {
    position: absolute;
    right: -8px;
    color: rgb(0 103 165);
    font-size: 1.5rem;
    text-shadow: 0 0 3px #FFF;
}

.service-report-previewImage-remove{

}

.service-report-objectName {
    font-size:1.1rem;
    margin-top:1rem;
    margin-bottom:0.5rem;
}

.component-header {
    position: relative;
    font-size: 2.0rem;
    padding-bottom: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    line-height: 2.5rem;
}

.component-header.text-border:after {
    content: '';
    width: 9.375rem;
    left: 4.75rem;
    height: 5px;
    position: absolute;
    bottom: 0;
    transform: translate(-50%,50%);
    background-color: #0067a5;
}

.service-report-table-md-labels {
    font-weight:800;
}

.service-report-table-sm-labels {
    font-weight: 800;
}


.fa-circle-xmark {
    text-shadow: 3px 6px rgba(255,165,0,.75)
}

label.large {
    font-size: 1.0rem;
    color: #000;
}

label {
    font-size: 0.85rem;
    color: #666;
}

.spinner-border-small {
    width:1.2rem;
    height:1.2rem;
    color:#666;
    margin-right:0.35rem;
}

.spinner-border-label{
    color:#666;
    font-size:1.0rem;
}

.error-label{
    color:#694242;
    font-size:1.0rem;
}

.error-text {
    margin-top:0.25rem;
    color: #877171;
    font-size: 0.8rem;
}

.btn-outline-primary.add-image {
    width: 180px;
    height: 180px;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: rgb(0 103 165);
    --bs-btn-border-color: rgb(0 103 165);
    --bs-btn-hover-color: rgb(0 103 165);
    --bs-btn-hover-bg: rgb(255 255 255);
    --bs-btn-hover-border-color: rgb(0 103 165);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: rgb(0 103 165);
    --bs-btn-active-border-color: rgb(0 103 165);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}

.btn-outline-primary {
    --bs-btn-color: rgb(0 103 165);
    --bs-btn-border-color: rgb(0 103 165);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgb(0 103 165);
    --bs-btn-hover-border-color: rgb(0 103 165);
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: rgb(0 103 165);
    --bs-btn-active-border-color: rgb(0 103 165);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: rgb(0 103 165);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: rgb(0 103 165);
    --bs-gradient: none;
}

.btn-secondary {
    --bs-btn-color: rgb(0 103 165);
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgb(0 103 164);
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}

.btn-sm, .btn-group-sm > .btn {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 1.75rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-border-radius: 1.0rem;
}

.btn {
    --bs-btn-disabled-opacity: 0.30;
}